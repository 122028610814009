@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
body {
    background-color: #e9ebee !important;
    font-family: 'Roboto', sans-serif;
}

html {
    --antd-wave-shadow-color: none !important;
}

.card {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 70%;
    border-radius: 5px;
    transition: all 500ms ease;
    margin: 10px 8px;
    height: 100%;
    overflow: hidden;
}

.match-score {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin: 10px 10px 10px 0;
    padding: 0 10px;
    background-color: #ffffff;
}

.score-child {
    margin: 0 6px;
}

.player {
    background-color: #ffc14d;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    text-align: center;
}

.set-game {
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #e9ebee;
    text-align: center;
    width: 70px;
    border-radius: 5px;
}

.social-login {
    border-radius: 4px !important;
    width: 100% !important;
    font-size: 16px !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding: 0 !important;
    line-height: 1.5715 !important;
    height: 32px !important;
    margin: 0 !important;
}

.card1 {
    background-color: #ffffff;
    border: 1px solid #e0e0ee !important;
    width: 35%;
    padding: 40px 30px 20px;
    border-radius: 5px;
    transition: all 500ms ease;
}

.icon-color {
    color: #1890ff;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mouse-effect {
    cursor: pointer;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-table {
    height: 100% !important;
    background-color: #ffc14d !important;
    border-color: #ffc14d !important;
    color: #ffffff !important;
}

.btn-o {
    background-color: #ffc14d !important;
    border-color: #ffffff !important;
    color: #ffffff !important;
    flex: 1;
}

.in-section {
    background-color: #1890ff !important;
    border-color: #1890ff !important;
    color: #1890ff !important;
}

.section-in {
    border: 1px solid #ffffff !important;
}

.left-border {
    border-left: 1px solid #ffffff !important;
}

.right-border {
    border-right: 1px solid #ffffff !important;
}

.btn-corners {
    width: 20%;
}

.mid-btn {
    width: 100%;
}

.corner-width {
    width: 70%;
}

.child-btn {
    width: 30%;
}

.corner-childs {
    padding: 0 !important;
}

.child-width {
    width: calc(100% / 3) !important;
}

.corners {
    width: 20%;
    height: 100%;
}

.corner-btn {
    height: 50% !important;
}

.mid-height {
    height: 100% !important;
}

.mid-width {
    width: 60%;
}

.flex-one {
    flex: 1;
}

.flex-two {
    flex: 2;
    height: 100% !important;
}

.flex-four {
    flex: 5;
}

.active-btn {
    background-color: #ADD8E6 !important;
    color: #ADD8E6 !important;
}

.ant-btn {
    border-radius: 0 !important;
}

.google-login {
    margin: 10px 0 !important;
    border-radius: 4px !important;
    width: 100% !important;
    font-size: 16px !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding: 0 !important;
    line-height: 1.5715 !important;
    height: 32px !important;
}

.heading {
    font-size: 24px;
    font-weight: bold;
}

.break-line {
    border-top: 0.5px solid #0000003a;
    margin: 0 -30px;
}

.setting-heading {
    font-size: 20px;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 5px;
}

.head-center {
    text-align: center;
}

.form-input {
    border-radius: 4px !important;
}

.form-button {
    border-radius: 4px !important;
    color: #ffffff !important;
}

.add-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
    width: 95%;
}

.add-button {
    width: 200px;
}

.header {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 50px;
}

.drawer-header {
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: #ffffff;
}

.social-buttons {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
}

.menu-flex {
    display: flex;
    flex-direction: row;
}

.helper-comp {
    position: relative;
    width: 100%;
}

.home-main {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.card-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.card2 {
    background-color: #ffffff;
    border: 1px solid #e0e0ee !important;
    width: 70%;
    padding: 40px 30px 20px;
    border-radius: 5px;
    transition: all 500ms ease;
    overflow: hidden;
}

.card3 {
    background-color: #ffffff;
    border: 1px solid #e0e0ee !important;
    width: 90%;
    padding: 40px 30px 20px;
    border-radius: 5px;
    transition: all 500ms ease;
    overflow: hidden;
    margin-bottom: 60px;
}

.verify-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.verify-text {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #1890ff;
}

.verify-head {
    margin-bottom: -10px;
    text-align: center;
    font-size: 80px;
    font-weight: 600;
    color: #1890ff;
}

.verify-div {
    height: 100vh;
    background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
    background-image: linear-gradient(180deg, #dfedf0, #e6dada);
}

.ant-select-selector {
    border-radius: 4px !important;
}

.card-box {
    display: flex;
    justify-content: center;
    margin: 20px 0 10px;
}

.form-layout {
    flex: 1;
}

.left-layout {
    margin-right: 3px !important;
}

.right-layout {
    margin-left: 3px !important;
}

.card-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto !important;
}

@media(max-width: 1200px) {
    .card1 {
        width: 45%;
    }
    .ant-btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media(max-width: 1000px) {
    .card1 {
        width: 60%;
    }
}

@media(max-width: 900px) {
    .card {
        width: 80%;
    }
    .card1 {
        width: 65%;
    }
    .card2 {
        width: 80%;
    }
    .ant-btn {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

@media(max-width: 800px) {
    .card {
        margin: 10px 6px;
    }
    .card1 {
        width: 70%;
    }
    .ant-btn {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    .tennis-button>span {
        font-size: 12px !important;
    }
}

@media(max-width: 700px) {
    .card1 {
        width: 75%;
    }
    .card2, .card3 {
        width: 90%;
        padding: 30px 20px 20px;
    }
    .ant-btn {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .tennis-button>span {
        font-size: 10px !important;
    }
}

@media(max-width: 600px) {
    .card1 {
        width: 80%;
        padding: 20px 20px 10px;
    }
    .break-line {
        margin: 0 -20px;
    }
    .flex-mobile {
        flex-direction: column;
    }
    .left-layout {
        margin-right: 0 !important;
    }
    .right-layout {
        margin-left: 0 !important;
    }
    .card2 {
        width: 95%;
    }
    .card {
        width: 88%;
    }
}

@media(max-width: 500px) {
    .card1 {
        width: 85%;
    }
    .heading {
        font-size: 20px;
    }
    .card2, .card3 {
        width: 96%;
        padding: 15px 10px 20px;
    }
    .card {
        width: 98%;
    }
}

@media(max-width:450px) {
    .ant-btn {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

@media(max-width: 400px) {
    .card1 {
        width: 90%;
    }
    .ant-list-item-meta-description {
        font-size: 12px !important;
        word-wrap: break-word;
    }
    .ant-list-item-meta-title>a {
        word-wrap: break-word;
        font-size: 14px;
    }
}

@media(max-width: 300px) {
    .card, .card1 {
        width: 95%;
    }
    .heading {
        font-size: 18px;
    }
}